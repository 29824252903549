import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Nav, Button, Collapse, Badge } from "react-bootstrap"
import styles from "./right-nav.module.scss"

export default function BlogRightNav() {
  const data = useStaticQuery(graphql`
    query CategoriesRecentPosts {
      recentPosts: allMdx(
        filter: { frontmatter: { type: { in: "post" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 5
      ) {
        nodes {
          frontmatter {
            permalink
            title
            category
            date
          }
        }
      }
      categories: allMdx(
        filter: { frontmatter: { type: { in: "post" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        distinct(field: frontmatter___category)
      }
    }
  `)

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleNavClick = () => {
    setMobileOpen(!mobileOpen)
  }

  const recentPosts = data.recentPosts.nodes.map(e => e.frontmatter)
  const categories = data.categories.distinct

  const drawer = (
    <>
      <div>
        <h5 className={`${styles.subTitle}`}>Recent Posts</h5>
      </div>
      <ul
        className={`${styles.menuList} ${styles.list} ${styles.menuItemBody} ${styles.recentPost} list-unstyled`}
      >
        {recentPosts.map(post => {
          return (
            <Link
              className={`${styles.menuItemLink}`}
              to={post.permalink}
              key={post.date}
            >
              <div className={`${styles.menuItemLinkText}`}>{post.title}</div>
            </Link>
          )
        })}
      </ul>
      <div>
        <h5 className={`${styles.subTitle}`}>Categories</h5>
      </div>
      <ul
        className={`${styles.menuList} ${styles.list} ${styles.menuItemBody} list-unstyled`}
      >
        {categories.map(category => {
          return (
            <Link
              className={`${styles.menuItemLink}`}
              key={category}
              to={`/category/${category}`}
            >
              <Badge
                pill
                variant={"primary"}
                className={`px-1 ${styles.badge}`}
              >
                {category}
              </Badge>
            </Link>
          )
        })}
      </ul>
    </>
  )

  return (
    <>
      <div className={`d-flex d-lg-none`}>
        <Button onClick={handleNavClick} aria-expanded={mobileOpen}></Button>
        <Collapse in={mobileOpen}>
          <Nav aria-label="platforms">{drawer}</Nav>
        </Collapse>
      </div>
      <aside className={`d-none d-lg-block  ${styles.aside}`}>
        <div
          aria-label={"platforms"}
          className={`${styles.sidebar} d-flex flex-column`}
        >
          <div>{drawer}</div>
        </div>
      </aside>
    </>
  )
}
