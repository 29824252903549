import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Hackers built enterprise chat and voice bots in 48 hours.`}</h2>
    <figure className="wp-caption">
  <figcaption className="wp-caption-text">
    Arte Merritt, Dashbot CEO &amp; co-founder, kicks off the hackathon with a
    keynote.
  </figcaption>
    </figure>
This past weekend, we hosted a 2-day bot hackathon at Microsoft Reactor where
attendees built bots on messaging and voice platforms. The creative thinking,
problem solving, and execution demonstrated by each of the teams were incredibly
impressive.
    <p>{`The two requirements were:`}</p>
    <ol>
      <li parentName="ol">{`Bots needed to solve a business problem.`}</li>
      <li parentName="ol">{`Teams must integrate Dashbot analytics.`}</li>
    </ol>
    <p>{`Teams were very dynamic and consisted of developers, designers, marketers, psychologists, data scientists and more. Most opted to build their bot using Node.js, Python, and Microsoft Bot Framework. Many were determined to have their bot be cross-platform and included multiple integrations with tools like Slack, Stack Overflow, Google Hangouts, Google Calendar, and QR codes.`}</p>
    <p>{`What was particularly fascinating was how many of the teams built test bots on multiple platforms to really understand and leverage the potential of each interface. The teams thoroughly thought through how best to provide a business solution and who their target audience would be for each conversational platform.`}</p>
    <p>{`Lastly, and most importantly, was the opportunity we had to help people integrate conversational analytics into the bots they just built.`}</p>
    <blockquote>
      <p parentName="blockquote">{`There’s something really magical about watching a crowd of people integrate and use your product, in real-time and in-person.`}</p>
    </blockquote>
    <p>{`It is a hard-hitting, tangible reminder that real people use your product and these real people have real questions. Hackathons put your product to the test as people comb through every piece of documentation, tutorial, and example.`}</p>
    <h2>{`The Projects`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Bot Reactor:`}</strong>{` A Facebook Messenger bot that helps you locate items inside of any store.`}</li>
      <li parentName="ol"><strong parentName="li">{`Botpop Up:`}</strong>{` A Google Action that streamlines the ordering process between merchants and customers by leveraging Slack integrations.`}</li>
      <li parentName="ol"><strong parentName="li">{`Calvite:`}</strong>{` A Google Action for school administrators to efficiently create appointments with parents and staff.`}</li>
      <li parentName="ol"><strong parentName="li">{`Coderbot:`}</strong>{` A chatbot that democratizes knowledge of software development teams.`}</li>
      <li parentName="ol"><strong parentName="li">{`DBots:`}</strong>{` A Facebook Messenger bot that facilitates event discovery and scheduling.`}</li>
      <li parentName="ol"><strong parentName="li">{`Refujob:`}</strong>{` A Facebook Messenger bot that helps refugees find and apply for jobs.`}</li>
      <li parentName="ol"><strong parentName="li">{`k2 bot:`}</strong>{` A Slack bot that checks in with employees about workplace mental wellness.`}</li>
      <li parentName="ol"><strong parentName="li">{`LBOT:`}</strong>{` A Facebook Messenger bot that finds and curates learning resources.`}</li>
      <li parentName="ol"><strong parentName="li">{`Botax:`}</strong>{` A multi-platform bot that helps with your taxes.`}</li>
      <li parentName="ol"><strong parentName="li">{`Team Empowered:`}</strong>{` A Slack bot that aids workplace mental wellness by collecting feedback.`}</li>
      <li parentName="ol"><strong parentName="li">{`FABot:`}</strong>{` A native chatbot that provides text and video instructions for assembling Ikea products.`}</li>
      <li parentName="ol"><strong parentName="li">{`Psycho:`}</strong>{` A native chatbot that helps professional video gamers with their mental wellness during tournaments.`}</li>
      <li parentName="ol"><strong parentName="li">{`Ballo Bot:`}</strong>{` A Facebook Messenger bot that helps writers collect all of their notes and thoughts in one place.`}</li>
    </ol>
    <h2>{`The Winners`}</h2>
    <h3>{`1st place — Botax: A multi-platform bot that helps with your taxes.`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/11/06172030/d66fe-1bqw4pzyifg_skvi_84nnog.jpeg",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}{`Mehdi Ahmadov, Samira Allahverdiyeva `}{`&`}{` Rauf Babayev received 3 Amazon Echos.`}</p>
    <h3>{`2nd Place — Refujob : A Facebook Messenger bot that helps refugees find and apply for jobs.`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/11/06172029/ffce3-1mbjvouivuhabe-wzenagcq.jpeg",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}{`Adil Bukhari, Adnan Ahmed, `}{`&`}{` Afif Syed received 3 Google Home Minis.`}</p>
    <h3>{`3rd Place — Coderbot: A native chatbot that democratizes knowledge of software development teams.`}</h3>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/11/06172028/de10a-1lzmc-5ibxkvpjp9_ig-3da.jpeg",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}{`Raimie Tang, Aakash Wadhwani, `}{`&`}{` Chinmay Mishra received a Bottie McBottie.`}</p>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=hackathon2017wrapup"
        }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</em></p>
    <p><em parentName="p">{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=hackathon2017wrapup"
        }}>{`www.dashbot.io`}</a>{`.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      