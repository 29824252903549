import React from 'react';
import SEO from '../../components/seo';
import Footer from '../../components/footer/footer';
import styles from './layout.module.scss';
import { Container, Col, Row, Image } from 'react-bootstrap';
import MdxProvider from '../../utils/mdx-provider';
import BlogRightNav from '../../page-components/blog/right-nav/right-nav';
import { get, has } from 'lodash';
import { parseThumbnail } from '../../utils/frontmatter-parse';
import logoLetters from "../../images/dashbot_logo_w.png";
import { Link } from 'gatsby';

const main = (props) => {
  const { children, pageContext } = props;

  return (
    <MdxProvider>
      <div className={`overflow-hidden`}>
        <SEO title={`Dashbot`} />
        <div className={`${styles.banner}`}>
          <Container>
            <Link to={'/'}><h2><Image
              fluid
              className={` ${styles.logoLetters} ml-2 d-none d-md-inline`}
              src={logoLetters}
            />/blog</h2></Link>
          </Container>
        </div>
        <Container>
          <Row>
            <Col xs={12} md={9}>
              <main className={`${styles.main}`}>
                {has(pageContext, 'frontmatter.thumbnail') && get(pageContext, 'frontmatter.thumbnail') ? (
                  <Image src={parseThumbnail(get(pageContext, 'frontmatter.thumbnail'))} className={styles.img} />
                ) : null}
                {children}
              </main>
            </Col>
            <Col md={3} className={`d-none d-md-block`}>
              <BlogRightNav />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    </MdxProvider>
  );
};

export default main;
